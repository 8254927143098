import React, { useState } from 'react';

import { Button, Text } from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';

import BrowseByMakeModal from './BrowseByMakeModal';
import BrowseByStyleModal from './BrowseByStyleModal';
import styleCodes from './references/browseByStyleCodes';

function BrowseByLinks({
    makes,
}) {
    const { sendClick } = useAnalytics();

    const makeList = makes?.options[1]?.options?.map((make) => make.label) || [];

    const [showStylesModal, setShowStylesModal] = useState(false);
    const [showMakesModal, setShowMakesModal] = useState(false);

    const browseByStyleModalClick = (event) => {
        if (!showStylesModal) {
            sendClick('browsByStyleClick', event, {
                firePixall: true,
                pageType: 'homepage',
            });
        }
        setShowStylesModal((prev) => !prev);
    };

    const browseByMakeModalClick = (event) => {
        if (!showMakesModal) {
            sendClick('browsAllMakesClick', event, {
                pageType: 'homepage',
            });
        }

        setShowMakesModal((prev) => !prev);
    };

    return (
        <div className="display-flex justify-content-start justify-content-sm-end gap-1 gap-sm-2 text-size-200 text-size-sm-300 text-bold">
            <Button
                bsStyle="text"
                data-cmp="lnk-browseByMake"
                title="Browse All Makes"
                onClick={browseByMakeModalClick}
            >
                Browse All Makes
            </Button>
            <Text weight="normal">|</Text>
            <Button
                bsStyle="text"
                data-cmp="lnk-browseByStyle"
                title="Browse All Styles"
                onClick={browseByStyleModalClick}
            >
                Browse All Styles
            </Button>
            <BrowseByStyleModal
                showModal={showStylesModal}
                onShowHideModalClick={browseByStyleModalClick}
                styleCodes={styleCodes}
            />
            <BrowseByMakeModal
                showModal={showMakesModal}
                onShowHideModalClick={browseByMakeModalClick}
                makes={makeList}
            />
        </div>
    );
}

export default BrowseByLinks;
